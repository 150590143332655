/* src/components/PhotoPopup.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px 0;
}

.popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.photo-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-item {
  position: relative;
  margin-bottom: 10px;
}

.popup-image {
  width: 80%;
  border-radius: 10px;
}

.select-checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2px;
  border-radius: 3px;
}
