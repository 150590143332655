/* src/App.css */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New styles from Google Photos app */
.photos-container {
  margin-top: 20px;
}

.photos-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.photo-item {
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
}

.photo-item.selected {
  border: 2px solid blue;
}

.photos-grid img {
  max-width: 100px;
  max-height: 100px;
}

.select-checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2px;
  border-radius: 3px;
}

.selected-photos {
  margin-top: 20px;
}

.date-navigation {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.date-navigation button {
  padding: 5px 10px;
  font-size: 16px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.popup-image {
  max-width: 100%;
  max-height: 80vh;
}
