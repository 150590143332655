/* src/components/PhotoPopupWithGeo.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px 0;
}

.popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.photo-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-item {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-item img {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.photo-item select {
  margin-top: 10px;
  width: 80%;
  align-self: center;
}
