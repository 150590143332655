/* src/components/JournalEntry.css */

.photos-grid {
  display: flex;
  flex-wrap: wrap;
}

.photo-item {
  margin: 5px;
}

/* Comment out or remove the following lines
.photo-item img {
  max-width: 100px;
  max-height: 100px;
}
*/
