/* src/components/DiffPopup.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.popup-header, .popup-footer {
  flex-shrink: 0;
}

.popup-body {
  display: flex;
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;
}

.popup-body > div {
  flex: 1;
  padding: 0 10px;
}

.popup-content .highlight {
  background-color: yellow;
}
